import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const NoHomologados = Loadable(lazy(() => import('views/pages/homologaciones/NoHomologados')));
const Homologados = Loadable(lazy(() => import('views/pages/homologaciones/Homologados')));
const SinPrecios = Loadable(lazy(() => import('views/pages/precios/SinPrecios')));
const ConPrecios = Loadable(lazy(() => import('views/pages/precios/ConPrecios')));
const SinPesos = Loadable(lazy(() => import('views/pages/pesos/SinPesos')));
const ConPesos = Loadable(lazy(() => import('views/pages/pesos/ConPesos')));
const Maestro = Loadable(lazy(() => import('views/pages/maestro/MaestroProductos')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'homologaciones/homologados',
            element: <Homologados />
        },
        {
            path: 'homologaciones/no-homologados',
            element: <NoHomologados />
        },
        {
            path: 'maestro',
            element: <Maestro />
        },
        {
            path: 'precios/sin-precio',
            element: <SinPrecios />
        },
        {
            path: 'pesos/sin-peso',
            element: <SinPesos />
        },
        {
            path: 'precios/con-precio',
            element: <ConPrecios />
        },
        {
            path: 'pesos/con-peso',
            element: <ConPesos />
        }
    ]
};

export default MainRoutes;
