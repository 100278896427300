// assets
import { IconEqual, IconEqualNot, IconBusinessplan } from '@tabler/icons';
import {
    CompareArrows,
    LocalAtm,
    List,
    Scale,
    MoneyOff,
    AttachMoney,
    ContentPasteSearch,
    ContentPaste,
    ContentPasteOff
} from '@mui/icons-material';

// constant
const icons = {
    IconEqual,
    IconBusinessplan,
    IconEqualNot,
    Scale,
    List,
    CompareArrows,
    LocalAtm,
    AttachMoney,
    MoneyOff,
    ContentPasteSearch,
    ContentPaste,
    ContentPasteOff
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const opciones = {
    id: 'opciones',
    title: 'Opciones',
    type: 'group',
    children: [
        {
            id: 'homologaciones',
            title: 'Homologaciones',
            type: 'collapse',
            icon: icons.CompareArrows,

            children: [
                {
                    id: 'homologados',
                    title: 'Homologados',
                    type: 'item',
                    url: '/homologaciones/homologados',
                    target: false,
                    icon: icons.IconEqual
                },
                {
                    id: 'nohomologados',
                    title: 'No homologados',
                    type: 'item',
                    url: '/homologaciones/no-homologados',
                    target: false,
                    icon: icons.IconEqualNot
                }
            ]
        },
        {
            id: 'precios',
            title: 'Precios',
            type: 'collapse',
            icon: icons.LocalAtm,

            children: [
                {
                    id: 'conprecio',
                    title: 'Precios histórico',
                    type: 'item',
                    url: '/precios/con-precio',
                    target: false,
                    icon: icons.AttachMoney
                },
                {
                    id: 'sinprecio',
                    title: 'Sin precio',
                    type: 'item',
                    url: '/precios/sin-precio',
                    target: false,
                    icon: icons.MoneyOff
                }
            ]
        },
        {
            id: 'pesos',
            title: 'Pesos',
            type: 'collapse',
            icon: icons.ContentPasteSearch,

            children: [
                {
                    id: 'conpeso',
                    title: 'Pesos histórico',
                    type: 'item',
                    url: '/pesos/con-peso',
                    target: false,
                    icon: icons.ContentPaste
                },
                {
                    id: 'sinpeso',
                    title: 'Sin peso',
                    type: 'item',
                    url: '/pesos/sin-peso',
                    target: false,
                    icon: icons.ContentPasteOff
                }
            ]
        },
        {
            id: 'maestro',
            title: 'Maestro Productos',
            type: 'item',
            icon: icons.List,
            url: '/maestro',
            target: false
        }
    ]
};

export default opciones;
